<template>
  <router-view v-if="['KoLPopularCaseCategory','KoLPopularCaseCategoryBoard'].includes($route.name)" />
  <PageHeaderLayout v-else>
    <div class='main-page-content'>
      <ApeMenu :menus='menus' />
    </div>
  </PageHeaderLayout>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'SignInfoIndex',
  components: {
    PageHeaderLayout
  },
  data() {
    return {
      menus: [
        {
          icon: 'liebiao',
          display_name: ' KOL信息',
          url: '/kol/list',
          permission_name: 'kol_info_list'
        },
        {
          icon: 'ziyuanku',
          display_name: '红人资源库-小红书',
          url: '/kol/XhsResources',
          permission_name: 'kol_resource_list'
        },
        {
          icon: 'douyin',
          display_name: '红人资源库-抖音',
          url: '/kol/TiktokResources',
          permission_name: 'kol_resource_list'
        },
        {
          icon: 'qianyue',
          display_name: ' 项目列表',
          url: '/kol/project',
          permission_name: 'kol_project_list'
        },
        {
          icon: 'qianyue',
          display_name: ' 归档记录',
          url: '/kol/KoLArchiveList',
          permission_name: 'kol_archive_list'
        },
        {
          icon: 'qianyue',
          display_name: ' 爆款案例',
          url: '/kol/KoLPopularCase',
          permission_name: 'popular_case_list'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
